let deviceWorkerMixin = {
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      page: 1,
      pageSize: 50,
      tableData: []
    }
  },
  beforeDestroy() {
    this.$bus.off('updateWorkerList', this.onRefresh)
  },
  mounted() {
    this.$bus.on('updateWorkerList', this.onRefresh)
  },
  methods: {
    start: function(res) {
      this.$push('deviceView1/deviceWorkerDeal', res)
    },
    onRefresh: function() {
      this.page = 1
      this.finished = false
      this.refreshing = true
      this.getDataList().then(res => {
        this.refreshing = false
        this.finished = true
        this.tableData = res.list
      })
    },
    onLoad: function() {
      this.finished = false
      this.loading = true
      this.getDataList().then(res => {
        this.finished = true
        this.loading = false
        this.tableData = res.list
      })
    },
    getDataList: function() {
      return new Promise(resolve => {
        this.$api.deviceRepair
          .reportList({
            queryType: this.queryType,
            page: this.page,
            pageSize: this.pageSize
          }).then(res => {
          let list = []
          if (this.$valueIsExist(res, 'data')) {
            list = res['data']
          }
          resolve({ list: list })
        }).catch(e => {
          resolve({ list: [] })
        })
      })

    }
  }
}

export default deviceWorkerMixin