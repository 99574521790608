<template>
  <div class="deviceWorkCell" @click="pushDetail">
    <div class="deviceWorkCellContent">
      <div class="deviceWorkCellHeader">
        <div class="deviceWorkCellTitle">
          维修预约时间
        </div>
        <div class="deviceWorkCellTime">
          {{ repairTime }}
        </div>
        <div class="deviceWorkCellStatus" :style="{color:statusColor}">{{ orderstatus }}</div>
        <div style="clear: both"></div>
      </div>
      <div class="deviceWorkCellDevice">
        <img class="deviceImg" :src="image">
        <div class="deviceContent">
          <div class="totalCell">
            {{ deviceName }}
          </div>

          <div class="contentCell">
            <div class="title">设备分类</div>
            <div class="cellMsg">{{ categoryName }}</div>
            <div style="clear: both"></div>
          </div>

          <div class="contentCell">
            <div class="title">{{$VUEAPPDEVICECODE()}}</div>
            <div class="cellMsg">{{ deviceCode }}</div>
            <div style="clear: both"></div>
          </div>

          <div class="contentCell">
            <div class="title">报障时间</div>
            <div class="cellMsg">{{ reportTime }}</div>
            <div style="clear: both"></div>
          </div>

        </div>
        <div class="line"></div>
        <div style="clear: both"></div>
      </div>

      <div class="deviceWorkCellDevice">
        <div>
          <div class="contentCell">
            <div class="title">订单编号</div>
            <div class="cellMsg_text_right">{{ serialNumber }}</div>
            <div style="clear: both"></div>
          </div>
		  <div class="contentCell">
		    <div class="title">预计维修时间</div>
		    <div class="cellMsg_text_right">{{ dataInfo.estimatedRepairTime|formatDateTime }}</div>
		    <div style="clear: both"></div>
		  </div>

          <div class="contentCell">
            <div class="title">联系人</div>
            <div class="cellMsg_text_right">{{ reportUser }}</div>
            <div style="clear: both"></div>
          </div>
		  <div class="contentCell">
		    <div class="title">联系电话</div>
		    <div class="cellMsg_text_right">{{ dataInfo.phone||'-' }}</div>
		    <div style="clear: both"></div>
		  </div>

          <div class="contentCell">
            <div class="title">上门地址</div>
            <div class="cellMsg_text_right">
              {{ address }}
            </div>
            <div style="clear: both"></div>
          </div>

          <!-- <div class="contentCell">
            <div class="title">下单时间</div>
            <div class="cellMsg_text_right">{{ repairTime }}</div>
            <div style="clear: both"></div>
          </div> -->

        </div>

        <div style="clear: both"></div>
      </div>

      <div class="footer">
        <van-button class="footerButton" @click.stop="start">{{ statusTitle }}</van-button>
      </div>


    </div>
  </div>
</template>

<script>
import shareDeviceOrderStatus from '@components/shareDeviceOrderStatus/shareDeviceOrderStatus'

export default {
  name: 'deviceWorkCell',
  mixins: [shareDeviceOrderStatus],
  computed: {
    image() {
      if (this.$valueIsExist(this.dataInfo, 'photo') && this.dataInfo['photo'] != '') {
       let photo = this.dataInfo['photo']
        return `${window.ipConfig.VUE_APP_BASEURL}/api/image/get?id=${this.$getPhotosId(photo)}`
      }
      return this.$replacePhoto('')
    },
    statusTitle() {
      if (this.status == 10 || this.status == 12) {
        return '重新维修'
      }
      if (this.status == 16 || this.status == 11) {
        return '查看'
      }
      return '维修'
    },
    status() {
      let status = 0
      if (this.$valueIsExist(this.dataInfo, 'status')) {
        status = this.dataInfo['status']
      }
      return status
    },
    statusColor() {
      return this.statusData.color
    },
    statusData() {
      return this.getStatusData(this.status, this.workerRepairOptions)
    },
    orderstatus() {
      return this.statusData.name
    },
    deviceCode() {
      if (this.$valueIsExist(this.dataInfo, 'deviceCode') == false) {
        return ''
      }
      return this.dataInfo['deviceCode']
    },
    categoryName() {
      if (this.$valueIsExist(this.dataInfo, 'categoryName') == false) {
        return ''
      }
      return this.dataInfo['categoryName']
    },
    address() {
      if (this.$valueIsExist(this.dataInfo, 'address') == false) {
        return ''
      }
      return this.dataInfo['address']
    },
    serialNumber() {
      if (this.$valueIsExist(this.dataInfo, 'serialNumber') == false) {
        return ''
      }
      return this.dataInfo['serialNumber']
    },
    reportUser() {
      if (this.$valueIsExist(this.dataInfo, 'reportUser') == false) {
        return ''
      }
      return this.dataInfo['reportUser']
    },
    deviceName() {
      if (this.$valueIsExist(this.dataInfo, 'deviceName') == false) {
        return ''
      }
      return this.dataInfo['deviceName']
    },
    reportTime() {
      if (this.$valueIsExist(this.dataInfo, 'reportTime') == false) {
        return ''
      }
      return this.formatDate(this.dataInfo['reportTime'])
    },
    repairTime() {
      if (this.$valueIsExist(this.dataInfo, 'repairTime') == false) {
        return ''
      }
      return this.formatDate(this.dataInfo['repairTime'])
    }
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    pushDetail: function() {
      let id = ''
      if (this.$valueIsExist(this.dataInfo, 'id')) {
        id = this.dataInfo['id']
      }

      let query = { id: id }
      this.$push('deviceView1/deviceWorkDetail', query)
    },
    start: function() {
      let id = ''
      if (this.$valueIsExist(this.dataInfo, 'id')) {
        id = this.dataInfo['id']
      }

      let query = { id: id }

      if (this.status == 5 || this.status == 10 || this.status == 12) {
        query['status'] = 5
      }

      this.$emit('start', query)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.deviceWorkCell {
  width: 100%;

  .deviceWorkCellContent {
    width: calc(100% - 20px);
    height: 100%;
    padding: 5px 5px 0px 5px;
    background-color: white;
    border-radius: 6px;
    overflow: hidden;
    margin-left: 10px;
    margin-top: 10px;

    .deviceWorkCellHeader {
      width: 100%;

      .deviceWorkCellTitle {
        width: 100px;
        line-height: 20px;
        font-size: 15px;
        float: left;
        font-weight: bold;
      }

      .deviceWorkCellTime {
        width: calc(100% - 180px);
        line-height: 20px;
        font-size: 15px;
        float: left;
        color: red;
        font-weight: bold;
      }

      .deviceWorkCellStatus {
        width: 70px;
        line-height: 20px;
        font-size: 15px;
        float: right;
        color: $color_primary;
        text-align: right;
      }
    }

    .deviceWorkCellDevice {
      width: 100%;
      padding: 15px 10px 5px 10px;


      .deviceImg {
        width: 70px;
        height: 70px;
        border-radius: 8px;
        float: left;
      }

      .deviceContent {
        width: calc(100% - 90px);
        float: right;
        margin-left: 5px;

        .totalCell {
          width: 100%;
          height: 20px;
          line-height: 20px;
          font-size: 13px;
          font-weight: bold;
        }


      }

      .line {
        margin-top: 5px;
        width: 100%;
        height: 1px;
        background-color: $border_color1;
        float: right;
      }

    }

    .contentCell {
      width: 100%;

      .title {
        width: 80px;
        height: 20px;
        line-height: 20px;
        float: left;
        font-size: 13px;
        color: $color3;
      }

      .cellMsg {
        float: right;
        width: calc(100% - 90px);
        height: 20px;
        line-height: 20px;
        font-size: 13px;
        color: $color3;
        word-wrap: break-word;
        word-break: break-all;
      }

      .cellMsg_text_right {
        float: right;
        width: calc(100% - 90px);
        line-height: 20px;
        font-size: 13px;
        color: $color3;
        text-align: right;
        word-wrap: break-word;
        word-break: break-all;
      }

    }

    .footer {
      width: 100%;

      .footerButton {
        width: 120px;
        height: 32px;
        border-radius: 16px;
        overflow: hidden;
        background-color: white;
        color: $color_primary;
        border: 1px solid $color_primary;
        float: right;
        margin-bottom: 10px;
      }

    }


  }

}

</style>